// GLOBAL VARIABLE DEFINITIONS

//screen sizes
$extra-small-screen-max: 340px;
$small-screen-max: 430px;
$medium-screen-max: 830px;

//colors
$colors: (
  primary: #d81e05
);
$primary: #d81e05;
$fmm-secondary: #be0f0f;
$fmm-tertiary: #820404;
$fmm-primary-text: #2d373d;
$fmm-secondary-text: #526570;
$fmm-white1: #ffffff;
$fmm-white2: #f5f6f7;
$fmm-grey1: #667985;
$fmm-grey2: #c9c9c9;
$fmm-grey3: #e8ebed;
$fmm-orange: #ef9454;
$fmm-green: #008c4b;
$fmm-green2: #bce1a3;
$fmm-blue: #00b4ff;
$fmm-light-blue: #effaff;
$fmm-border-input-grey: #dedede;
$fmm-black: #000000;

//font weights
$font-weight-bold: 500;
$font-weight-normal: normal;

//font sizes
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 17px;
$font-size-l: 20px;
$font-size-xl: 22px;
$font-size-xxl: 31px;

//font family
$font-family: 'DM Sans', sans-serif;

//paddings, margins and distances
$zero: 0px;
$padding-sel-alert: 8px 8px 8px 8px;
$padding-xs: 8px;
$padding-s: 12px;
$padding-m: 24px;
$padding-l: 36px;
$padding-xl: 48px;
$padding-xxl: 60px;
$bar-button-padding: 0 $padding-m 0 $padding-m;
$margin-10: 10px;
$margin-20: 20px;
$rem03: 0.3rem;

//item lvl1 header
$lvl1-width: 25%;
$lvl1-heigth: 100px;
$lvl1-width2: 33%;

//menu subtext
$menu-sub-text-max-width: 50%;
$menu-title-padding: 0 $padding-xl 0;
$menu-title-width: 50%;
$menu-title-hight: 42px;
$menu-icon-size: 32px;
$menu-header-icon-size: 43px;
$menu-item-hight: 55px;
$menu-span-top: 33%;
$menu-badge-size: 10px;
$menu-badge-margin-top: 5px !important;

//padding textarea
$padding-ta: 4px;

$fmm-textarea-min-height: 7rem;

$default-margin: 4px;

//margin-datetime
$margin-datetime: 8px 8px 8px 0px;
$fmm-datepicker-margin-left: 72px;
$no-margin: 0px;
$fmm-datepicker-input-height: 40px;

//select input ios variables
//icon size (the alert type have a hidden icon that moves the span)
$fmm-select-ios-alert-height: 16px;
$fmm-select-ios-alert-width: 16px;
$fmm-select-ios-alert-heading-padding: 24px 24px 20px;
$fmm-select-ios-alert-buttons-margin: 0 8px 0 0;
$fmm-select-ios-alert-buttons-padding: 10px;
$fmm-select-ios-alert-rgoup-buttons-padding: 8px 8px 8px 24px;
$alert-md-max-width: 100%;

//Radio button ios checked styles
$fmm-radio-ios-ch-top: 0px;
$fmm-radio-ios-ch-left: 7px;
$fmm-radio-ios-ch-width: 8px;
$fmm-radio-ios-ch-height: 15px;

//border buttons
$border-radius: 24px;
$border: 0.5px solid;

//border textarea
$border-radius-ta: 2.5px;

//border elements
$default-border: 1px solid;

//box-shadow for select inputs
$box-shadow-select: 0px 4px 5.2px 0.3px rgba(180, 180, 180, 0.24), 0px 4px 5.2px 0.3px rgba(180, 180, 180, 0.24);

//fab constants
$fmm-fab-position: 10px;
$fmm-fab-bottom-min-size: 56px;
$fmm-fab-margin-horizontal: 0 66px;
$fmm-fab-margin-vertical: 66px 0;
$fmm-fab-reverse-distance: 0px;
$fmm-fab-disable-opacity: 0.4;
$fmm-fab-label-height: 24px;
$fmm-fab-label-max-width: 140px;
$fmm-fab-label-line-height: 16px;
$fmm-fab-label-margin-top: 20px;
$fmm-fab-label-border-radius: 5px;
$fmm-fab-left-distance: -155px;
$fmm-fab-width: 370%;
$fmm-fab-label-margin-left-position-true: 60px;
$fmm-fab-label-margin-left-position-false: 50px;
$fmm-fab-label-max-width-position-false: 400px;
$fmm-fab-label-width-position-false: 400px;
$fmm-fab-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.1);
$fmm-fab-margin-side-horizontal: 8px 6px;
$fmm-fab-icon-padding-ios: 10px;
$fmm-fab-bottom-height: 40px;
$fmm-fab-bottom-width: 40px;
$fmm-fab-label: 5px 5px 5px 5px;

//icon size
$icon-font-size: 36px;
$icon-size: 36px;

//img size
$img-size: 56px;

//headerImage height
$fmm-header-height: 100%;

//header height
$header-mobile-height: 60px;
$header-height: 72px;
$fmm-header-boder-hover: 4px solid;
$fmm-header-span-height: 32px;

//summary content
$summary-content-close: '\f3d0';
$summary-content-open: '\f3d8';
$summary-font-size: 28px;
$summary-width: 24px;

//steps
//Change variables to customize
$fmm-step-height-s: 6rem;
$fmm-step-height-m: 8rem;
$fmm-step-height-l: 10rem;

$fmm-step-padding: 3rem;
$fmm-step-arrow-padding: 10px;
$fmm-step-button-horizontal-scroll-height: 35px;
$fmm-step-button-horizontal-scroll-margin: 3rem;
$fmm-step-button-horizontal-scroll-padding: 5px;
$fmm-step-button-horizontal-width: 25px;
$fmm-step-button-horizontal-height: 25px;
$fmm-step-button-vertical-width: 36px;
$fmm-step-button-vertical-height: 36px;
$fmm-step-label-vertical-height: 33px;

//tabs
$fmm-transition-duration: 300ms;
$fmm-highlight-width: 1px;
$fmm-highlight-height: 2px;
$fmm-tab-margin-top: 6px;

$fmm-textarea-defaultheight: 48px;

//toggle
$fmm-toggle-max-width: 51px;
$fmm-toggle-max-height: 32px;
$fmm-toggle-md-max-width: 36px;
$fmm-toggle-md-max-height: 14px;

//label
$fmm-label-min-height: 1.2rem;
$fmm-label-datepicker-min-width: 10%;
