// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$grey-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import '~@angular/material/theming';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@import 'theme/mapfreVariables.scss';
@import 'theme/variables.scss';
@import 'theme/responsive.scss';

ion-icon {
  height: 22px;
  padding-right: 12px;
  padding-left: 12px;
}

ion-button {
  min-width: 160px;
  margin-right: 8px;
  margin-left: 8px;
  text-transform: none;
  --border-radius: 7px !important;
  border-radius: 7px !important;
  height: 40px;
  // & ion-icon {
  //   color: $fmm-white1;
  //   --color: $fmm-white1;
  // }
  &[disabled] {
    background-color: $fmm-grey2 !important;
    --background: $fmm-grey2 !important;
  }
  &.fmm-link,
  &.fmm-link-border {
    --background: none;
    --box-shadow: none;
    color: $primary;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    &:hover {
      opacity: 0.9;
    }
    &[disabled] {
      color: $fmm-grey2;
      background: none !important;
      --background: none;
    }
  }
  &.fmm-link-border {
    border: solid 1px $primary;
    height: 56px;
    font-weight: bold !important;
    font-stretch: normal !important;
    letter-spacing: 0.21px !important;
  }
}

.red {
  color: $primary;
}

.mat-horizontal-stepper-header-container {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

.mat-step-header .mat-step-icon-selected {
  background-color: $primary !important;
  border: 1px solid $primary !important;
}

.mat-step-header .mat-step-icon-state-done {
  background-color: $primary !important;
}

.mat-step-header .mat-step-icon {
  color: #9cb0bc;
  background-color: transparent;
  border: 1px solid #9cb0bc;
}

.mat-step-header .mat-step-icon.mat-step-icon-selected {
  color: $fmm-white2;
}

mat-step-header:nth-of-type(4),
mat-step-header:nth-of-type(5),
mat-step-header:nth-of-type(6),
.mat-stepper-horizontal-line:nth-of-type(4),
.mat-stepper-horizontal-line:nth-of-type(5),
.mat-stepper-horizontal-line:nth-of-type(6),
.isAportacionDiaria mat-step-header:nth-of-type(1),
.isAportacionDiaria mat-step-header:nth-of-type(2)::before,
.isAportacionDiaria .mat-stepper-horizontal-line:nth-of-type(1) {
  display: none !important;
}

@for $i from 1 through 3 {
  mat-step-header:nth-of-type(#{$i}) .mat-step-icon-content::after {
    content: '#{$i}';
  }
  .isAportacionDiaria mat-step-header:nth-of-type(#{$i}) .mat-step-icon-content::after {
    content: '#{$i - 1}';
  }
}

@for $i from 7 through 8 {
  mat-step-header:nth-of-type(#{$i}) .mat-step-icon-content::after {
    content: '#{$i - 3}';
  }
  .isAportacionDiaria mat-step-header:nth-of-type(#{$i}) .mat-step-icon-content::after {
    content: '#{$i - 4}';
  }
}

@for $i from 1 through 8 {
  .last-edited-step-#{$i} {
    @for $j from 1 through $i {
      mat-step-header:nth-of-type(#{$j}) {
        & .mat-step-icon {
          background-color: $primary !important;
          border: 1px solid $primary;
          color: $fmm-white2;
        }

        & + .mat-stepper-horizontal-line {
          height: 3px;
          background-color: $primary;
        }
        &::after {
          border-top-width: 3px !important;
          border-top-color: $primary !important;
        }
      }
      mat-step-header:nth-of-type(#{$j + 1}) {
        & .mat-step-icon {
          background-color: $primary !important;
          border: 1px solid $primary;
          color: $fmm-white2;
        }
        &::before {
          border-top-width: 3px !important;
          border-top-color: $primary !important;
        }
      }
    }
  }
  .current-edited-step-#{$i} {
    mat-step-header:nth-of-type(#{$i}) {
      &::after {
        border-top-width: 3px !important;
        border-top-color: $primary !important;
      }
      & + .mat-stepper-horizontal-line {
        height: 3px;
        background-color: $primary;
      }
      & .mat-step-label.mat-step-label-active {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 500;
      }
    }
  }
  .current-edited-step-4,
  .current-edited-step-5,
  .current-edited-step-6 {
    mat-step-header:nth-of-type(3) {
      & .mat-step-label {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 500;
      }
    }
  }
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.54);
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
[dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
[dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  width: calc(50% - 11px) !important;
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
  stroke: $primary;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 8px 0;
}

.mat-form-field-outline-start {
  border-radius: 0 !important;
}

.mat-form-field-outline-end {
  border-radius: 0 !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field-infix {
  border-top: 0.45em solid transparent;
}

html,
body {
  height: 100%;
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  background-color: $fmm-white1;
}

label {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.21px;
  color: $fmm-primary-text;
}

.backgroundImg {
  width: 100%;
  margin-top: auto;
  div {
    height: 300px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }
}

.mat-radio-outer-circle,
.mat-radio-container,
.mat-radio-inner-circle {
  height: 24px !important;
  width: 24px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $fmm-green;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $fmm-green;
}

.card {
  width: 300px;
  height: 150px;
  border: 2px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
}

mat-error {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.18px;
  color: #da2a2a !important;
}

.mat-form-field-invalid .mat-input-element {
  color: $primary !important;
}

.mat-form-field-invalid .mat-form-field-wrapper {
  background-color: #faf0f2;
}

.mat-input-element {
  color: $fmm-secondary-text !important;
}

*:not(.material-icons) {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: $font-family !important;
}
b,
strong {
  font-weight: bold !important;
}
h1 {
  font-size: 31px;
  color: $fmm-primary-text;
  line-height: 1.29;
  letter-spacing: 0.4px;
  margin: 15px auto 15px auto;
  max-width: 700px;
  line-height: 1.29;
  letter-spacing: 0.4px;
}

h2 {
  font-size: 31px;
  line-height: 1.29;
  letter-spacing: 0.4px;
  color: $fmm-primary-text;
  margin-top: 35px;
}

h3 {
  color: $fmm-primary-text;
  font-size: 27px;
  line-height: 1.33;
  letter-spacing: 0.35px;
  color: $fmm-primary-text;
}

h4 {
  color: $fmm-primary-text !important;
  font-size: 23px;
  font-weight: 500;
  line-height: 1.39;
  letter-spacing: 0.3px;
}

h5 {
  font-size: 17px;
  color: $fmm-secondary-text !important;
  line-height: 1.41;
  letter-spacing: 0.22px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

h6 {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: $fmm-primary-text;
}

tooltip-box {
  padding: 8px !important;
  width: 246px;
  background-color: $fmm-secondary-text !important;
  border-radius: 6px;
  text-align: center;
}

.tooltip-box {
  text-align: left;
}

.google-visualization-tooltip {
  min-width: fit-content;
  padding: 10px 13px !important;
  min-height: 40px;
  text-align: center;
  border: none !important;
}

.tooltip-box,
.google-visualization-tooltip {
  font-size: 14px !important;
  background-color: $fmm-secondary-text !important;
  line-height: 1.43;
  letter-spacing: 0.18px;
  border-radius: 6px !important;
  box-shadow: 0 2px 2px 0 rgba(82, 101, 112, 0.2) !important;
  color: $fmm-white1;
}

.mat-ink-bar {
  display: none;
}

app-bloque-informacion rect {
  height: 370px;
}

app-bloque-seguridad-familiar rect,
app-bloque-jubilacion rect {
  fill: none;
}

.chartColumn svg,
.chartColumn svg > rect {
  height: 364px;
}

mat-form-field {
  width: 100%;
  .mat-form-field-wrapper {
    background-color: $fmm-white1;
  }
}

.mat-checkbox {
  margin-top: 10px;
  margin-bottom: 4px;
}

.mat-checkbox .mat-checkbox-frame {
  background-color: $fmm-white1;
  border-radius: 2px;
  border: solid 1px $fmm-secondary-text;
  background-color: $fmm-white1;
}

.mat-checkbox .mat-checkbox-inner-container {
  height: 24px;
  width: 24px;
}

.mat-checkbox-checked .mat-checkbox-inner-container {
  background-color: $fmm-white1;
  border-radius: 2px;
  border: solid 1px $fmm-secondary-text;
  background-color: $fmm-white1;
}

.mat-checkbox-label {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43 !important;
  letter-spacing: 0.18px;
  color: $fmm-secondary-text;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $fmm-white1;
}
.mat-checkbox-checkmark-path {
  stroke: $fmm-green !important;
}

.has-arrow::before {
  border: 10px solid transparent !important;
}

.has-arrow.arrow-top::before {
  border-bottom-color: $fmm-secondary-text !important;
  border-bottom-width: 10px;
  top: -20px !important;
}

.mat-horizontal-content-container {
  overflow: visible !important;
  height: 100%;
}

app-aportacion-diaria,
app-dynamic-step-loader,
app-tienes-ahorros,
app-ahorro-cuentas-bancarias,
app-ahorro-inversion,
app-jubilacion,
app-tu-edad,
.mat-horizontal-stepper-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pieChart svg path + path,
.pieChart svg ellipse {
  display: none;
}

.columnChart svg g g g g rect + rect {
  display: none;
}

.mat-horizontal-stepper-content[aria-expanded='false'] {
  min-height: 0 !important;
  overflow: hidden;
  height: 0;
}

.mat-dialog-container {
  height: auto !important;
}