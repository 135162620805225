

@media (max-width: 599px) {
  .mat-horizontal-content-container {
    padding: 0 !important;
  }
  ::ng-deep .mat-dialog-container {
    padding: 10px !important;
  }
  .mat-dialog-container {
    margin: 16px !important;
    padding: 8px !important;
    height: auto !important;
  }
  .mat-dialog-content {
    padding: 0 !important;
    margin: 0 !important;
  }
  ion-button {
    max-width: 110px !important;
    min-width: 110px !important;
    width: 110px !important;
    font-size: 14px !important;
  }

  h2 {
    font-size: 23px !important;
  }
  h2,
  h5 {
    text-align: left;
  }

  .component {
    margin: 15px;
  }

  .component h2 {
    margin-top: 0;
  }

  .btnPanelUp {
    position: auto !important;
    bottom: 0 !important;
  }

  .backgroundImg div {
    height: 180px !important;
  }
}
